import { formatDate } from '~/utils/date'

const oneHour = 1000 * 60 * 60
const oneDay = oneHour * 24

const padDate = number => number.toString().padStart(2, '0')

export const computeCalendarDays = (month, year) => {
  const today = new Date()
  const currentMonth = new Date(year, month)
  const nextMonth = new Date(year, month + 1)
  const prevMonth = new Date(year, month - 1)

  const currentFirstDay = currentMonth.getDay()
  const nextFirstDay = nextMonth.getDay()
  const currentLastDate = Math.ceil((nextMonth.getTime() - currentMonth.getTime() - oneHour) / oneDay)
  const prevLastDate = Math.ceil((currentMonth.getTime() - prevMonth.getTime() - oneHour) / oneDay)

  const dayFn = (dateMonth, callback, another = true) => (_, i) => {
    const day = callback(i)
    const month = dateMonth.getMonth()
    const year = dateMonth.getFullYear()
    const dateString = `${padDate(day)}.${padDate(month + 1)}.${year}`
    const current = formatDate(today) === dateString

    return { another, day, month, year, current, dateString }
  }

  const prevCount = (currentFirstDay + 6) % 7
  const currentCount = currentLastDate
  const nextCount = (nextFirstDay === 1 ? 0 : 7 - (nextFirstDay + 6) % 7)
  const sum = prevCount + currentCount + nextCount
  const additionalCount = sum <= 7 * 5 ? (sum <= 7 * 4 ? 14 : 7) : 0

  const prevDays = Array.from({ length: prevCount }, dayFn(prevMonth, i => prevLastDate - i)).reverse()
  const currentDays = Array.from({ length: currentCount }, dayFn(currentMonth, i => i + 1, false))
  const nextDays = Array.from({ length: nextCount + additionalCount }, dayFn(nextMonth, i => i + 1))

  return [
    ...prevDays,
    ...currentDays,
    ...nextDays
  ]
}

const addLessonsToDay = (day, lessons) => ({
  ...day,
  lessons: lessons.filter(lesson => formatDate(lesson.book.datetime_start) === day.dateString)
})

export const combineSchedule = (days, lessons) => days.map(day => addLessonsToDay(day, lessons))

export const normalizeLesson = (data, book) => {
  const normalized = Object.assign({}, data, { book })
  delete normalized.books
  return normalized
}

export const getNearestLesson = (items) => {
  const now = new Date()
  let diff = Infinity
  let nearest = null

  items.forEach(item => {
    item.books.forEach(book => {
      const currentDiff = book.datetime_start - now
      if (currentDiff <= 0 || diff <= currentDiff) return
      diff = currentDiff
      nearest = normalizeLesson(item, book)
    })
  })

  return nearest
}

export const LessonsNormalize = {
  fromBookings: items => {
    const lessons = []
    items.forEach(item => {
      item.books.forEach(book => {
        lessons.push(normalizeLesson(item, book))
      })
    })
    return lessons
      .sort((a, b) => a.book.datetime_start - b.book.datetime_start)
      .map(l => normalizeLesson(l, l.book))
  }
}
