<template>
  <ClientOnly>
    <div class="calendar" :class="{ 'disabled': disabled }">
      <div class="top">
        <div class="title">Расписание</div>
        <div class="month-control">
          <DirectionalButton direction="left" @click="prev" />
          <div class="month-name">{{ monthName }} {{ year }}</div>
          <DirectionalButton direction="right" @click="next" />
        </div>
      </div>
      <table class="table">
        <thead>
        <tr class="week-names">
          <th><span>Пн</span></th>
          <th><span>Вт</span></th>
          <th><span>Ср</span></th>
          <th><span>Чт</span></th>
          <th><span>Пт</span></th>
          <th><span>Сб</span></th>
          <th><span>Вс</span></th>
        </tr>
        </thead>
        <tbody>
        <tr class="week" v-for="(week, weekIndex) in timetable" :key="weekIndex">
          <CalendarCell
            v-for="(day, dayIndex) in week"
            :key="weekIndex * 7 + dayIndex"
            :day="day"
            @click="emit('selectDay', day)"
            :selected="day.dateString === selectedDay?.dateString"
          />
        </tr>
        </tbody>
      </table>
    </div>
  </ClientOnly>
</template>

<script setup>
import DirectionalButton from '@/components/common/DirectionalButton'
import CalendarCell from '@/components/calendar/CalendarCell'
import { combineSchedule, computeCalendarDays } from '~/utils/calendar'
import chunk from '~/utils/chunk'
import months from '@/data/months'

const route = useRoute()
const props = defineProps({
  lessons: {
    type: Array,
    default: () => ([])
  },
  disabled: {
    type: Boolean,
    default: () => false
  },
  selectedDay: Object
})
const emit = defineEmits(['selectDay'])

const now = new Date()
const month = ref(now.getMonth())
const year = ref(now.getFullYear())

const timetable = computed(() => {
  const days = computeCalendarDays(month.value, year.value)
  const schedule = combineSchedule(days, props.lessons)
  return chunk(schedule, 7)
})

if (route.query.lessons) {
  const firstLesson = route.query.lessons.split(',')[0]
  const lessonDateString = firstLesson.split(' ')[0]

  timetable.value.forEach(timetableWeek => {
    timetableWeek.forEach(timetableDay => {
      if (timetableDay.dateString === lessonDateString) {
        emit('selectDay', timetableDay)
      }
    })
  })
}

const monthName = computed(() => months[month.value])
const selected = ref(null)

function prev () {
  if (month.value === 0) {
    month.value = 11
    year.value--
  } else {
    month.value--
  }
  selected.value = null
}

function next () {
  if (month.value === 11) {
    month.value = 0
    year.value++
  } else {
    month.value++
  }
  selected.value = null
}
</script>

<style scoped lang="scss">
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 465px) {
    flex-direction: column;
    gap: 20px;
  }
  & .title {
    font-weight: 800;
    font-size: 24px;
    line-height: 28px;
  }
  & .month-control {
    display: flex;
    align-items: center;
    & .month-name {
      font-weight: 500;
      font-size: 20px;
      line-height: 27px;
      width: 175px;
      text-align: center;
    }
  }
}

.table {
  width: 100%;
  margin-top: 10px;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 9px;
  & th {
    width: 1fr;
    padding: 10px 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: var(--color-elements-secondary);
    text-transform: uppercase;
  }
}

.calendar.disabled {
  opacity: .3;
  pointer-events: none;
}
</style>
