<template>
  <div class="lesson-list">
    <div v-if="lessons.length > displayCount" class="lesson-item lesson-more" :title="moreTitle">{{ more }}</div>
    <template v-for="(lesson, i) in lessons.slice(0, lessons.length > displayCount ? displayCount - 1 : lessons.length).reverse()" :key="i">
      <img
        v-if="lesson.avatar_url"
        :src="lesson.avatar_url"
        alt="Аватар"
        class="lesson-item"
        :title="title(lesson)"
      >
      <AvatarPlaceholder
        v-else
        :data="lesson"
        light
        class="lesson-item"
        :title="title(lesson)"
      />
    </template>
  </div>
</template>

<script setup>
import AvatarPlaceholder from '@/components/common/AvatarPlaceholder'
import { formatTime } from '~/utils/date'

const props = defineProps({
  lessons: {
    type: Array,
    required: true
  },
  displayCount: {
    type: Number,
    default: 4
  }
})

const title = (lesson) => `Занятие в ${formatTime(lesson.book.datetime_start)} - ${lesson.first_name} ${lesson.last_name}`
const moreTitle = computed(
  () => props.lessons
    .slice(props.displayCount - 1)
    .map(l => title(l))
    .join('\n')
)
const more = computed(() => {
  const number = props.lessons.length - props.displayCount + 1
  return number > 9 ? '9+' : `+${number}`
})
</script>

<style scoped lang="scss">
.lesson-list {
  display: flex;
  justify-content: flex-end;
  margin-right: -6px;
  & .lesson-item {
    box-sizing: border-box;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 3px solid var(--day-bg-color);
    margin-right: -10px;
    object-fit: cover;
    @media (max-width: 920px) {
      margin-right: -6px;
      width: 24px;
      height: 24px;
    }
    &:last-child {
      margin-right: 0;
    }
    &.lesson-more {
      background: var(--day-circle-color);
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-right: -6px;
      @media (max-width: 920px) {
        font-size: 10px;
        line-height: 14px;
      }
    }
  }
}
</style>
