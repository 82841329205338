import { formatDate } from '~/utils/date'

export default function useDaySelection (lessons = null) {
  const selectedDay = ref(null)
  const selectedDayLessons = lessons !== null
    ? computed(() => selectedDay.value ? lessons.value.filter(lesson => formatDate(lesson.book.datetime_start) === selectedDay.value.dateString) : [])
    : null

  const selectDay = day => {
    if (selectedDay.value?.dateString === day.dateString) selectedDay.value = null
    else selectedDay.value = day
  }

  return {
    selectedDay,
    ...(selectedDayLessons !== null ? { selectedDayLessons } : {}),
    selectDay
  }
}
